.top_nav {
    padding: 2%;
    background-color: #aaa;
    display: flex;
    flex-direction: column;
}

.top_nav_title {
    margin: 0;
}

/* See index.css for generic link rules */
.top_nav_links {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}
